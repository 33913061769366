import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { History } from "history";
import { Panel, PanelGroup } from "react-resizable-panels";
import { useMeasure, useWindowSize } from "@uidotdev/usehooks";
import isFunction from "lodash/isFunction";
import {
  BaseLayout,
  MainContent,
  PingPanelResizeHandle,
} from "@repo/ping-react-js";

import { PVPanel } from "features/submission-dashboard/PVPanel";
import { PingVisionSubmissionList } from "features/submission-dashboard/PingVisionSubmissionList";
import { PVSubmissionDetails } from "src/features/submission-dashboard/PVSubmissionDetails";
import { PingVisionSidebar } from "features/submission-dashboard/PingVisionSidebar";
import { TeamMembersManagement } from "features/submission-dashboard/TeamMembersManagement";
import {
  DocumentType,
  DOCUMENT_TYPES,
  PVAllDocumentsPreviewPanel,
} from "features/submission-dashboard/PVAllDocumentsPreviewPanel";
import { PingCommandMenuModal } from "features/submission-dashboard/PingCommandMenu";
import { PVEmptyPanelMessage } from "features/submission-dashboard/PVEmptyPanelMessage";
import { PVRibbon } from "src/features/submission-dashboard/PVRibbon";
import {
  useGetSubmissionList,
  useGetSubmissionHistoryList,
  useGetAdvancedSearchFields,
  SEARCH_PARAM_NAME,
} from "features/submission-dashboard/queries";
import {
  useGetEnvironmentQuery,
  useGetSettingsQuery,
  useGetNavQuery,
} from "services/pvSlice";
import { useAppSelector } from "utils/redux";
import { useSlug, usePingId, useTeamId } from "utils/hooks";
import { usePanelSizes } from "features/submission-dashboard/usePanelSizes";
import {
  setSovs,
  setSubmissionHistory,
  setPreviewPdf,
  setClearPreview,
  setPreviewEmail,
  setAreSovsLoading,
} from "reducers/inbox";
import { setIsCommandMenuOpened } from "reducers/settings";
import { setSelectedSovItem } from "services/selectedSovSlice";
import { SovDataType } from "ts-types/DataTypes";
import { usePingVisionUrlStore } from "features/usePingVisionUrlStore";
import { APP_MODES } from "constants/SubmissionConstants";
import { PanelContext } from "utils/context";
import { DashboardPanel } from "features/submission-dashboard/panel-types";
import { useSearchQueryBuilder } from "@repo/ping-react-js";
import { useGetSingleSubmission } from "features/submission-dashboard/queries";

import "./PingVisionSubmissionDashboard.scss";

const SUPPRESS_LEFT_NAV_SCREEN_WIDTH_PX = 1440;
const SUPPRESS_SUBMISSION_LIST_SCREEN_WIDTH_PX = 1100;

const enrichSovData = (
  sovData: { results: SovDataType[] } | undefined,
  submissionStatuses: { id: number; name: string }[] | undefined,
) => {
  // TODO: this should be a selector so that we can take advantage of the Redux
  // Toolkit cache.
  //
  // Example code:
  //
  // import { createSelector } from '@reduxjs/toolkit';
  //
  // export const selectEnrichedSubmissions = createSelector(
  //   pingApi.endpoints.getSubmissions.select(),
  //   pingApi.endpoints.getStatuses.select(),
  //   (submissions, statuses) => {
  //     // If either query is loading or errored, return undefined
  //     if (!submissions.data || !statuses.data) {
  //       return undefined;
  //     }
  //
  //     // Map submissions to include full status information
  //     return submissions.data.map(submission => {
  //       const status = statuses.data.find(s => s.id === submission.statusId);
  //
  //       return {
  //         ...submission,
  //         status: status
  //           ? { name: status.name, description: status.description }
  //           : { name: 'Unknown', description: 'Status information not available' }
  //       };
  //     });
  //   }
  // );
  //
  // And later in the component:
  //
  // const enrichedSubmissions = useSelector(selectEnrichedSubmissions);
  //

  if (!sovData || !submissionStatuses) {
    return [];
  }

  return sovData.results.map((r) => ({
    ...r,
    workflow_status__name:
      submissionStatuses.find(
        (s) => s.id.toString() === r.workflow_status_id.toString(),
      )?.name || "Unknown",
  }));
};

const PingVisionSubmissionDashboard = () => {
  const pingId = usePingId();
  const teamId = useTeamId();
  const userId =
    useAppSelector((state) => state.settings?.envData?.user?.id) || 0;
  const dispatch = useDispatch();
  const firstLoadRef = useRef(true);
  const [showArchivedDocuments, setShowArchivedDocuments] = useState(false);
  const [isLeftNavVisible, setIsLeftNavVisible] = useState(true);
  const [isSubmissionListVisible, setIsSubmissionListVisible] = useState(true);
  const [isPreviewPanelCollapsed, setIsPreviewPanelCollapsed] = useState(true);
  const [initialPreviewType, setInitialPreviewType] =
    useState<DocumentType | null>(null);
  const navToQueryParams = useAppSelector(
    (state) => state.settings.navToQueryParams,
  );
  const slug = useSlug();

  const selectedSovItem = useAppSelector(
    (state) => state.selectedSov.selectedSovItem,
  );
  const submissionStatuses = useAppSelector(
    (state) => state.settings?.settings?.submission_status,
  );

  const { mode } = usePingVisionUrlStore();

  const detailMode = mode === APP_MODES.DETAIL;

  const isCommandMenuOpened = useAppSelector(
    (state) => state.settings.isCommandMenuOpened,
  );

  const keyForAdditionalFilters = teamId ? `${teamId}-${slug}` : slug || "";
  const additionalFilters = isFunction(
    navToQueryParams?.[keyForAdditionalFilters],
  )
    ? navToQueryParams?.[keyForAdditionalFilters](userId)
    : (navToQueryParams?.[keyForAdditionalFilters] ?? null);

  const history = useHistory();

  const { advancedSearchFields: fields } = useGetAdvancedSearchFields();
  const { searchValues } = useSearchQueryBuilder(
    fields,
    history as History<unknown>,
    SEARCH_PARAM_NAME,
  );

  const [sortConfig, setSortConfig] = useState<{
    field: "inception_date" | "created_time";
    direction: "asc" | "desc";
  }>({
    field: "created_time",
    direction: "desc",
  });

  const { data: submissionList, isLoading: isSubmissionListLoading } =
    useGetSubmissionList({
      ...(sortConfig?.field && sortConfig?.direction ? { sortConfig } : {}),
      searchValues,
    });

  // Update areSovsLoading in redux when isSubmissionListLoading changes
  useEffect(() => {
    dispatch(setAreSovsLoading(isSubmissionListLoading));
  }, [dispatch, isSubmissionListLoading]);

  // First try to find the item in the current sovs list
  const currentSelectedItem = useMemo(
    () => submissionList?.results?.find((sov) => sov.id === pingId),
    [submissionList?.results, pingId],
  );

  // If not found in current list but we have a pingId, use the stored selectedSovItem
  const selectedItem = useMemo(
    () => currentSelectedItem || (pingId ? selectedSovItem : null),
    [currentSelectedItem, selectedSovItem, pingId],
  );

  // Update selectedSovItem when a new item is selected
  useEffect(() => {
    if (currentSelectedItem) {
      dispatch(setSelectedSovItem(currentSelectedItem));
    }
  }, [currentSelectedItem, dispatch]);

  // if we don't have the item in the initial pagination results
  // but a ping id for it when the page is first loaded, let's get
  // that single item
  const shouldFetchSingle =
    firstLoadRef.current &&
    additionalFilters &&
    pingId &&
    submissionList?.results &&
    userId &&
    !selectedItem?.id;

  const { data: singleSubmission } = useGetSingleSubmission(
    pingId,
    {
      ...additionalFilters,
      ...(slug === "my-issues"
        ? {
            advancedSearchFields: {
              claimed_by_id: 4,
            },
          }
        : {}),
    },
    { skip: !shouldFetchSingle },
  );

  useEffect(() => {
    if (
      !isSubmissionListLoading &&
      submissionList?.results &&
      singleSubmission?.results?.[0]
    ) {
      if (firstLoadRef.current && singleSubmission?.results?.[0]) {
        dispatch(setSelectedSovItem(singleSubmission.results[0]));
      }
      firstLoadRef.current = false;
    }
  }, [submissionList, singleSubmission, dispatch, isSubmissionListLoading]);

  const documents = useMemo(
    () => selectedItem?.documents || [],
    [selectedItem],
  );

  const documentsByType = useMemo(() => {
    return DOCUMENT_TYPES.reduce(
      (acc, type) => {
        const filteredDocuments = documents.filter(
          (d) => d.document_type === type,
        );
        if (filteredDocuments.length > 0) {
          acc[type] = filteredDocuments;
        }
        return acc;
      },
      {} as Record<DocumentType, typeof documents>,
    );
  }, [documents]);

  const [localSelectedItem, setLocalSelectedItem] = useState(selectedItem);

  const previousSelectedItemId = useRef<string | null>(null);

  const handleOpenPreview = useCallback(() => {
    if (selectedItem && selectedItem.id) {
      dispatch(setClearPreview());

      if (Object.keys(documentsByType).length > 0) {
        const documentTypes = Object.keys(documentsByType);

        if (documentTypes.includes("EML")) {
          dispatch(setPreviewEmail(selectedItem.id));
        } else if (documentTypes.includes("ACORD")) {
          const currentActiveDocuments = documentsByType["ACORD"];
          const firstDoc = currentActiveDocuments[0];
          dispatch(setPreviewPdf(firstDoc.url));
        } else if (documentTypes.includes("LOSS_RUN")) {
          const currentActiveDocuments = documentsByType["LOSS_RUN"];
          const firstDoc = currentActiveDocuments[0];
          dispatch(setPreviewPdf(firstDoc.url));
        }
      }
    }
  }, [selectedItem, documentsByType, dispatch]);

  const handleCollapseExpand = useCallback(() => {
    setIsPreviewPanelCollapsed(!isPreviewPanelCollapsed);
    if (!isPreviewPanelCollapsed) {
      setInitialPreviewType(null);
      setShowArchivedDocuments(false);
    }
  }, [isPreviewPanelCollapsed]);

  useEffect(() => {
    setLocalSelectedItem(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    if (
      selectedItem?.id &&
      selectedItem.id !== previousSelectedItemId.current
    ) {
      previousSelectedItemId.current = selectedItem.id;
      setIsPreviewPanelCollapsed(false);
      handleOpenPreview();
    }
  }, [handleOpenPreview, selectedItem]);

  const handleSortChange = useCallback(
    (newSortConfig: typeof sortConfig) => {
      setSortConfig(newSortConfig);
      const params = new URLSearchParams(window.location.search);
      params.set("sort_field", newSortConfig.field);
      params.set("sort_direction", newSortConfig.direction);
      history.push({ search: params.toString() });
    },
    [history],
  );

  useGetSettingsQuery({});

  useGetNavQuery({});

  useGetEnvironmentQuery();

  const { data: sovHistoryData = [] } = useGetSubmissionHistoryList();

  useEffect(() => {
    dispatch(setSubmissionHistory(sovHistoryData));
  }, [dispatch, sovHistoryData]);

  useEffect(() => {
    const enrichedSovs = enrichSovData(submissionList, submissionStatuses);
    dispatch(setSovs(enrichedSovs));
  }, [dispatch, submissionStatuses, submissionList]);

  const windowSize = useWindowSize();

  // Check if we're in team management view based on URL path
  const isTeamManagementView =
    window.location.pathname.endsWith("/team-members");

  useEffect(() => {
    // Show/hide left nav based on window size.
    if (
      windowSize.width &&
      windowSize.width > SUPPRESS_LEFT_NAV_SCREEN_WIDTH_PX
    ) {
      setIsLeftNavVisible(true);
    } else {
      setIsLeftNavVisible(false);
    }

    // Always hide submission list in team management view
    if (
      windowSize.width &&
      windowSize.width <= SUPPRESS_SUBMISSION_LIST_SCREEN_WIDTH_PX
    ) {
      setIsSubmissionListVisible(false);
    } else {
      setIsSubmissionListVisible(true);
    }
  }, [windowSize, isTeamManagementView]);

  // To calculate panel sizes in percentages, we need the width of the left
  // ribbon.
  const [ribbonRef, { width: ribbonWidth }] = useMeasure();

  // Calculate panel sizes in percentages, based on a set of minimum and maximum
  // pixel sizes.
  const { getMinPanelSize, getMaxPanelSize } = usePanelSizes(
    windowSize.width,
    ribbonWidth,
  );

  return (
    <React.Fragment>
      <PanelContext.Provider
        value={{
          isLeftNavVisible,
          isSubmissionListVisible,
        }}
      >
        <PingCommandMenuModal
          isOpen={isCommandMenuOpened}
          onClose={() => {
            dispatch(setIsCommandMenuOpened(false));
          }}
        />

        <BaseLayout
          title="Ping Radar [beta]"
          shouldShowPoweredByPing={false}
          className="PingVisionSubmissionDashboard"
        >
          <MainContent
            hasTopPadding={false}
            paddingSize="slim"
            className="PingVisionSubmissionDashboard__Main"
          >
            {!detailMode && (
              <PVRibbon
                onClickToggle={() => setIsLeftNavVisible(!isLeftNavVisible)}
                hasBorder={isLeftNavVisible}
                ref={ribbonRef}
              />
            )}
            {isTeamManagementView ? (
              <PanelGroup direction="horizontal">
                <Panel
                  id={DashboardPanel.SIDEBAR}
                  order={1}
                  minSize={
                    isLeftNavVisible
                      ? getMinPanelSize(DashboardPanel.SIDEBAR)
                      : 0
                  }
                  maxSize={
                    isLeftNavVisible
                      ? getMaxPanelSize(DashboardPanel.SIDEBAR)
                      : 0
                  }
                >
                  <PVPanel background="none">
                    <PingVisionSidebar />
                  </PVPanel>
                </Panel>

                <PingPanelResizeHandle orientation="horizontal" width="slim" />

                <Panel
                  id={DashboardPanel.TEAM_MEMBER_FORM}
                  order={2}
                  minSize={getMinPanelSize(DashboardPanel.TEAM_MEMBER_FORM)}
                  maxSize={getMaxPanelSize(DashboardPanel.TEAM_MEMBER_FORM)}
                >
                  <PVPanel>
                    <TeamMembersManagement />
                  </PVPanel>
                </Panel>
              </PanelGroup>
            ) : (
              <PanelGroup direction="horizontal">
                <Panel
                  id={DashboardPanel.SIDEBAR}
                  order={1}
                  minSize={
                    isLeftNavVisible && !detailMode
                      ? getMinPanelSize(DashboardPanel.SIDEBAR)
                      : 0
                  }
                  maxSize={
                    isLeftNavVisible && !detailMode
                      ? getMaxPanelSize(DashboardPanel.SIDEBAR)
                      : 0
                  }
                >
                  {isLeftNavVisible && !detailMode && (
                    <PVPanel background="none">
                      <PingVisionSidebar />
                    </PVPanel>
                  )}
                </Panel>

                {isLeftNavVisible && !detailMode && (
                  <PingPanelResizeHandle
                    orientation="horizontal"
                    width="slim"
                  />
                )}

                <Panel
                  id={DashboardPanel.SUBMISSION_LIST}
                  order={2}
                  minSize={
                    slug && !detailMode && isSubmissionListVisible
                      ? getMinPanelSize(DashboardPanel.SUBMISSION_LIST)
                      : 0
                  }
                  maxSize={
                    slug && !detailMode && isSubmissionListVisible
                      ? getMaxPanelSize(DashboardPanel.SUBMISSION_LIST)
                      : 0
                  }
                >
                  <PVPanel>
                    <PingVisionSubmissionList
                      onSortChange={handleSortChange}
                      sortConfig={sortConfig}
                    />
                  </PVPanel>
                </Panel>

                {slug && !detailMode && isSubmissionListVisible && (
                  <PingPanelResizeHandle
                    orientation="horizontal"
                    width="slim"
                  />
                )}

                <Panel
                  id={DashboardPanel.SUBMISSION_DETAILS}
                  order={3}
                  minSize={getMinPanelSize(DashboardPanel.SUBMISSION_DETAILS)}
                  maxSize={getMaxPanelSize(DashboardPanel.SUBMISSION_DETAILS)}
                >
                  <PanelGroup direction="horizontal">
                    <Panel order={1} minSize={30} maxSize={70} defaultSize={60}>
                      {localSelectedItem ? (
                        <PVPanel>
                          <PVSubmissionDetails
                            setInitialPreviewType={setInitialPreviewType}
                            setShowArchivedDocuments={setShowArchivedDocuments}
                            selectedItem={localSelectedItem}
                            isPreviewPanelCollapsed={isPreviewPanelCollapsed}
                            onPreviewPanelCollapse={handleCollapseExpand}
                            hasPreviewableDocuments={
                              Object.keys(documentsByType).length > 0
                            }
                          />
                        </PVPanel>
                      ) : (
                        <PVPanel>
                          <PVEmptyPanelMessage message="Select a submission to view details" />
                        </PVPanel>
                      )}
                    </Panel>

                    {selectedItem && !isPreviewPanelCollapsed && (
                      <PingPanelResizeHandle
                        orientation="horizontal"
                        width="slim"
                      />
                    )}

                    <Panel
                      key={`${isPreviewPanelCollapsed?.toString()}-${selectedItem?.id?.toString()}`}
                      order={2}
                      minSize={
                        selectedItem && !isPreviewPanelCollapsed ? 30 : 0
                      }
                      maxSize={
                        selectedItem && !isPreviewPanelCollapsed ? 70 : 0
                      }
                    >
                      {selectedItem && !isPreviewPanelCollapsed ? (
                        <PVPanel>
                          <PVAllDocumentsPreviewPanel
                            initialShowArchived={showArchivedDocuments}
                            initialPreviewType={initialPreviewType}
                            selectedItem={selectedItem}
                            onCollapseExpand={handleCollapseExpand}
                          />
                        </PVPanel>
                      ) : null}
                    </Panel>
                  </PanelGroup>
                </Panel>
              </PanelGroup>
            )}
          </MainContent>
        </BaseLayout>
      </PanelContext.Provider>
    </React.Fragment>
  );
};

export default PingVisionSubmissionDashboard;
