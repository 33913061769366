export const PING_VISION_DEFAULT_GRID_PAGE_SIZE = 200;

export const PING_VISION_DEFAULT_FIELDS = [
  "created_time",
  "modified_time",
  "division__short_name",
  "company__short_name",
  "source__source_type",
  "source__inbox_email_address",
  "documents",
  "actions",
  "division_id",
  "workflow_status_id",
  "workflow_status__name",
  "ping_maps",
  "data_readiness_score",
  "data_readiness_notes",
  "team_id",
  "team__name",
  "global_request_id",
  "client_ref",
  "extra_data",
  "inception_date",
  "expiration_date",
  "insured_name",
  "insured_street",
  "insured_street2",
  "insured_city",
  "insured_state",
  "insured_zip",
  "insured_fein",
  "insured_country",
  "insured_business_description",
  "from_email",
  "home_state",
  "broker_name",
  "broker_email",
  "broker_street",
  "broker_street2",
  "broker_city",
  "broker_state",
  "broker_zip",
  "broker_fein",
  "broker_country",
  "triage_rule_results",
  "triage_rules_overall_result",
  "insured_website",
  "insured_contact_name",
  "insured_contact_email",
  "insured_contact_phone",
  "insured_sic",
  "insured_gl_code",
  "insured_naics",
  "limits_total_limit",
  "num_buildings",
  "statistics",
  "is_ping_ready",
  "jobs",
];
