import { useCallback, useEffect, useState } from "react";

import { DashboardPanel } from "features/submission-dashboard/panel-types";

const PANEL_SIZES_PX = {
  [DashboardPanel.SIDEBAR]: {
    min: 192,
    max: 256,
  },
  [DashboardPanel.SUBMISSION_LIST]: {
    min: 256,
    max: 416,
  },
  [DashboardPanel.SUBMISSION_DETAILS]: {
    min: 960,
  },
  [DashboardPanel.TEAM_MEMBER_FORM]: {
    /* submission details plus submission list */
    min: 1216,
  },
};

/**
 * The library we use for managing our resizable panels only supports passing
 * min/max sizes in percentage units. This is problematic because the sizes get
 * blown out of proportion on large screens, and squeezed into comically tiny
 * areas on small screens. We need pixel sizes. Luckily we have the ability to
 * calculate the screen size and DOM element sizes, which we can then use to
 * convert pixel sizes to percentages.
 */
const usePanelSizes = (
  windowWidth: number | null,
  ribbonWidth: number | null,
) => {
  const [panelSizesPercent, setPanelSizesPercent] = useState<
    | {
        [key in DashboardPanel]: {
          min: number;
          max: number;
        };
      }
    | null
  >(null);

  useEffect(() => {
    if (!windowWidth || !ribbonWidth) {
      return;
    }

    const effectiveWidth = windowWidth - ribbonWidth;

    // Calculate min/max size percentages for all the panels based on window
    // width.
    setPanelSizesPercent({
      [DashboardPanel.SIDEBAR]: {
        min:
          (PANEL_SIZES_PX[DashboardPanel.SIDEBAR].min / effectiveWidth) * 100,
        max:
          (PANEL_SIZES_PX[DashboardPanel.SIDEBAR].max / effectiveWidth) * 100,
      },
      [DashboardPanel.SUBMISSION_LIST]: {
        min:
          (PANEL_SIZES_PX[DashboardPanel.SUBMISSION_LIST].min /
            effectiveWidth) *
          100,
        max:
          (PANEL_SIZES_PX[DashboardPanel.SUBMISSION_LIST].max /
            effectiveWidth) *
          100,
      },
      [DashboardPanel.SUBMISSION_DETAILS]: {
        min:
          (PANEL_SIZES_PX[DashboardPanel.SUBMISSION_DETAILS].min /
            effectiveWidth) *
          100,
        max: 100,
      },
      [DashboardPanel.TEAM_MEMBER_FORM]: {
        min:
          (PANEL_SIZES_PX[DashboardPanel.TEAM_MEMBER_FORM].min /
            effectiveWidth) *
          100,
        max: 100,
      },
    });
  }, [windowWidth, ribbonWidth]);

  const getMinPanelSize = useCallback(
    (panel: DashboardPanel) => {
      if (!panelSizesPercent) {
        return undefined;
      }

      return panelSizesPercent[panel].min;
    },
    [panelSizesPercent],
  );

  const getMaxPanelSize = useCallback(
    (panel: DashboardPanel) => {
      if (!panelSizesPercent) {
        return undefined;
      }

      return panelSizesPercent[panel].max;
    },
    [panelSizesPercent],
  );

  return { getMinPanelSize, getMaxPanelSize };
};

export { usePanelSizes };
