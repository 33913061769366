import { FC, useCallback, useMemo, useState } from "react";
import cx from "classnames";
import find from "lodash/find";
import { PingFloatingSelectInput, PingMaterialIcon } from "@repo/ping-react-js";
import { useHistory } from "react-router-dom";

import { PAPERTRAIL, S3_RESULTS_BUCKET } from "constants/SubmissionConstants";
import {
  useBulkUpdateSubmissionMutation,
  useChangeSubmissionTriageStatusMutation,
  useMarkSubmissionAsDuplicateMutation,
} from "services/pvSlice";
import { useAppSelector, useAppDispatch } from "utils/redux";
import { SovDataType } from "ts-types/DataTypes";
import { MarkSubmissionDuplicateModal } from "components/modals";

import "./PVSubmissionDetailsToolbar.scss";
import { useTeamId } from "utils/hooks.ts";

type PVSubmissionDetailsToolbarProps = {
  selectedItem: SovDataType;
};

interface NavQueryParam {
  workflow_status_id?: number[];
  [key: string]: any;
}

export const PVSubmissionDetailsToolbar: FC<
  PVSubmissionDetailsToolbarProps
> = ({ selectedItem }) => {
  const mapsReady = selectedItem?.ping_maps?.status === "R";

  // We use this to show the loading state on the correct button when the
  // submissions list is being fetched. If we don't keep track of this, there's
  // no way to know which button triggered the loading state for the submission
  // list.
  const [lastAction, setLastAction] = useState<
    "assigneeChange" | "submissionChange" | null
  >(null);
  const history = useHistory();

  const inboxSlug = useAppSelector((state) => state.inbox.slug);
  const userId = useAppSelector((state) => state.settings?.envData?.user?.id);

  const teamId = useTeamId();
  const navToQueryParams = useAppSelector(
    (state) => state.settings.navToQueryParams,
  );

  const buildWorkflowToInboxMap = useCallback((): Record<number, string> => {
    const workflowToInboxMap: Record<number, string> = {};

    const teamPrefix = `${teamId}-`;
    Object.entries(navToQueryParams).forEach(([key, value]) => {
      if (key.startsWith(teamPrefix)) {
        const inboxType = key.replace(teamPrefix, "");
        const param = value as NavQueryParam;
        if (
          param?.workflow_status_id &&
          Array.isArray(param.workflow_status_id)
        ) {
          param.workflow_status_id.forEach((statusId: number) => {
            workflowToInboxMap[statusId] = inboxType;
          });
        }
      }
    });

    return workflowToInboxMap;
  }, [teamId, navToQueryParams]);

  const hasWorkflowMismatch = useCallback(
    (workflowStatusId: number, inboxSlug: string | null): boolean => {
      const workflowToInboxMap = buildWorkflowToInboxMap();
      const expectedInbox = workflowToInboxMap[workflowStatusId];
      const allowedSlugs = ["all", "my-issues", expectedInbox];

      return expectedInbox ? !allowedSlugs.includes(inboxSlug) : false;
    },
    [buildWorkflowToInboxMap],
  );

  const [
    openMarkSubmissionDuplicateModal,
    setOpenMarkSubmissionDuplicateModal,
  ] = useState(false);

  const [markSubmissionAsDuplicate] = useMarkSubmissionAsDuplicateMutation();

  const [changeSubmissionStatus, { isLoading: isLoadingChangeStatusRequest }] =
    useChangeSubmissionTriageStatusMutation();

  const isLoadingSubmissionList = useAppSelector(
    (state) => state.inbox.areSovsLoading,
  );

  const statusOptions = useMemo(() => {
    const options = Object.entries(selectedItem.actions.transition_to).map(
      ([value, label]) => ({ value, label }),
    );
    return [
      {
        value: selectedItem.workflow_status__name || "Unknown status",
        label: selectedItem.workflow_status__name || "Unknown status",
      },
      ...options,
    ];
  }, [selectedItem.actions.transition_to, selectedItem.workflow_status__name]);

  const dispatch = useAppDispatch();

  const onChangeStatus = useCallback(
    async (index: number) => {
      setLastAction("submissionChange");
      const status = statusOptions[index].value;
      try {
        await changeSubmissionStatus({ id: selectedItem.id, status });
        if (hasWorkflowMismatch(Number(status), inboxSlug)) {
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.delete("selected");
          history.replace(
            `${window.location.pathname}${searchParams ? `?${searchParams}` : ""}`,
          );
        }
      } catch (error) {
        console.error("Failed to change submission status", error);
      }
    },
    [
      hasWorkflowMismatch,
      changeSubmissionStatus,
      selectedItem.id,
      statusOptions,
      dispatch,
      history,
      inboxSlug,
    ],
  );

  const onMarkSubmissionAsDuplicate = useCallback(
    async (duplicateOfSubmissionId: string) => {
      await markSubmissionAsDuplicate({
        id: selectedItem.id,
        duplicate_of_submission_id: duplicateOfSubmissionId,
      });
    },
    [markSubmissionAsDuplicate, selectedItem.id],
  );

  const settings = useAppSelector((state) => state.settings.settings);
  const team = find(settings?.teams, { team_id: selectedItem?.team_id });

  const currentAssignee = useMemo(() => {
    return team?.users?.find((user) => user.id === selectedItem.claimed_by_id)
      ?.username;
  }, [selectedItem.claimed_by_id, team]);

  const assigneeOptions = useMemo(() => {
    const selected = team?.users?.find(
      (user) => user.id === selectedItem.claimed_by_id,
    );

    const options = [
      { label: "Unassigned", value: null },
      ...(selected ? [{ label: selected.username, value: selected.id }] : []),
      ...(settings?.teams?.[0]?.users
        ?.filter((user) => user.id !== selectedItem.claimed_by_id)
        ?.map((user) => ({
          value: user.id,
          label: user.username,
        })) || []),
    ];

    return options;
  }, [selectedItem.claimed_by_id, team, settings]);

  const selectAssigneeIndex = useMemo(() => {
    return assigneeOptions.findIndex(
      (option) => option.value === selectedItem.claimed_by_id,
    );
  }, [assigneeOptions, selectedItem.claimed_by_id]);

  const [bulkUpdateSubmission, { isLoading: isLoadingBulkSubmission }] =
    useBulkUpdateSubmissionMutation();

  const onChangeAssignee = useCallback(
    async (index: number) => {
      setLastAction("assigneeChange");
      const claimedById = assigneeOptions[index].value;

      await bulkUpdateSubmission({
        ids: [selectedItem.id],
        changes: [
          {
            action: "claim",
            parameters: { claimed_by_id: claimedById },
          },
        ],
      });

      if (inboxSlug === "my-issues" && claimedById !== userId) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("selected");
        history.replace(
          `${window.location.pathname}${searchParams ? `?${searchParams}` : ""}`,
        );
      }
    },
    [
      history,
      inboxSlug,
      userId,
      bulkUpdateSubmission,
      selectedItem.id,
      assigneeOptions,
    ],
  );

  return (
    <div className="PRSubmissionDetailsToolbar">
      <MarkSubmissionDuplicateModal
        isOpen={openMarkSubmissionDuplicateModal}
        onClose={() => {
          setOpenMarkSubmissionDuplicateModal(false);
        }}
        onClickYes={(duplicateOfSubmissionId: string) => {
          onMarkSubmissionAsDuplicate(duplicateOfSubmissionId);
          setOpenMarkSubmissionDuplicateModal(false);
        }}
        selectedItem={selectedItem}
      />
      <PingFloatingSelectInput
        options={assigneeOptions}
        selectedIndex={selectAssigneeIndex}
        setSelectedIndex={onChangeAssignee}
        placement="bottom-end"
        renderLabelElt={(_, isOpen) => {
          return (
            <PRToolbarButton
              label={currentAssignee || "Unassigned"}
              iconName="account_circle"
              isActive={isOpen}
              isLoading={
                isLoadingBulkSubmission ||
                (isLoadingSubmissionList && lastAction === "assigneeChange")
              }
            />
          );
        }}
      />

      <PingFloatingSelectInput
        options={statusOptions}
        selectedIndex={0}
        setSelectedIndex={onChangeStatus}
        placement="bottom-end"
        renderLabelElt={(_, isOpen) => {
          return (
            <PRToolbarButton
              label={selectedItem.workflow_status__name || "Unknown status"}
              iconName="directions"
              isActive={isOpen}
              isLoading={
                isLoadingChangeStatusRequest ||
                (isLoadingSubmissionList && lastAction === "submissionChange")
              }
            />
          );
        }}
      />

      <PRToolbarButton
        isDisabled={!mapsReady}
        label="Map"
        iconName="map"
        url={mapsReady ? selectedItem?.ping_maps?.url : null}
      />
      <PRToolbarButton
        label="Papertrail"
        iconName="receipt"
        url={`https://my.papertrailapp.com/groups/${PAPERTRAIL[import.meta.env.VITE_APP_ENV]}/events?q=${selectedItem?.global_request_id}`}
      />
      <PRToolbarButton
        label="Debug S3"
        iconName="folder"
        url={`https://us-east-1.console.aws.amazon.com/s3/buckets/${S3_RESULTS_BUCKET[import.meta.env.VITE_APP_ENV]}?region=us-east-1&bucketType=general&prefix=submissiondocuments/${selectedItem.id}/&showversions=false`}
      />
      <PRToolbarButton
        label="Mark as Duplicate"
        iconName="highlight_off"
        onClick={() => {
          setOpenMarkSubmissionDuplicateModal(true);
        }}
      />
    </div>
  );
};

type PRToolbarButtonProps = {
  label: string;
  iconName: string;
  url?: string | null;
  onClick?(): void;
  isActive?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
};

const PRToolbarButton: FC<PRToolbarButtonProps> = ({
  label,
  iconName,
  url,
  onClick,
  isActive,
  isLoading,
  isDisabled,
}) => {
  const appliedClasses = cx("PRToolbarButton", {
    "PRToolbarButton--IsActive": isActive,
    "PRToolbarButton--IsLoading": isLoading,
  });

  return url ? (
    <a className={appliedClasses} href={url} target="_blank">
      <PingMaterialIcon iconName={iconName} />
      <span>{label}</span>
    </a>
  ) : (
    <button className={appliedClasses} onClick={onClick} disabled={isDisabled}>
      <PingMaterialIcon iconName={iconName} />
      <span>{label}</span>
    </button>
  );
};
